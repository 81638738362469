import React from 'react'
import { FlexContainer } from '../elements'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { getImage } from '../imageContainers'
import { FOOTER } from '../CMS/Footer'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'

const Footer = ({ className, lang = "en" }) => {
  return (
    <footer>
      <FlexContainer
        alignItems="center"
        flexWrap="wrap"
        justifyContent="center"
        className={className}
        dir={lang === 'ar' ? 'rtl' : 'ltr'}
      >
        <Link to={lang && `/${lang}/contact-us`} className="link">
          {FOOTER[lang].text[0]}
        </Link>
        <Link to={lang && `/${lang}/terms`} className="link">
          {FOOTER[lang].text[1]}
        </Link>
        <Link to={lang && `/${lang}/privacy-policy`} className="link">
          {FOOTER[lang].text[2]}
        </Link>
        <p className="text">
          &#169; {FOOTER[lang].text[3]}{' '}
          <b>
            <a
              href={FOOTER[lang].link}
              style={{ color: '#000' }}
              target="blank"
            >
              {FOOTER[lang].text[4]}
            </a>
          </b>
        </p>
        <a href={FOOTER[lang].googlePlay} target="blank" className="link">
          {getImage(FOOTER[lang].googleImg)}
        </a>
        <a href="http://www.facebook.com/shamseyaeg" className="icons-link" target="blank" >
          <FaFacebookF className="icons"/>
        </a>
        <a href="https://www.linkedin.com/company/shamseya/" className="icons-link" target="blank">
          <FaLinkedinIn className="icons"/>
        </a>
      </FlexContainer>
    </footer>
  )
}

export default styled(Footer)`
  border-top: 1px solid #ddd;
  padding: 10px 0px;
  background: #eee;
  .link {
    color: black;
    cursor: pointer;
    margin: 0px 10px;
    font-size: 0.9rem;
  }
  .text {
    font-size: 0.7rem;
    margin: auto 5px;
    padding: 5px 5px;
    text-align: center;
  }
  .icons{
    color: #000;
    font-size: 1.2rem;
  }
  .icons-link{
    margin: 0 5px;
    align-self: center;
  }
`
