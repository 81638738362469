import { createGlobalStyle } from 'styled-components'
import HelveticaLight from '../fonts/HelveticaNeue-Light.otf'
import HelveticaMedium from '../fonts/HelveticaNeue-Medium.otf'
import HelveticaMediumCond from '../fonts/HelveticaNeue-MediumCond.otf'
import MyriadProBold from '../fonts/MyriadPro-Bold.otf'
import MyriadProRegular from '../fonts/MyriadPro-Regular.otf'
import BigJohm from '../fonts/Big-johm.otf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
       font-family: 'Helvetica-light';
       src: url(${HelveticaLight});
    }
  @font-face {
       font-family: 'Helvetica-Medium';
       src: url(${HelveticaMedium});
    }
  @font-face {
       font-family: 'Helvetica-MediumCond';
       src: url(${HelveticaMediumCond});
    }
  @font-face {
       font-family: 'Myriadpro-bold';
       src: url(${MyriadProBold});
    }
  @font-face {
       font-family: 'MyriadProRegular';
       src: url(${MyriadProRegular});
    }
  @font-face {
       font-family: 'BigJohm';
       src: url(${BigJohm});
    }

  * {
    font-family: 'Helvetica-light';
  }
  body {
    padding: 0;
    margin: 0;
    font-family: 'Helvetica-light';

  }
  a {
    text-decoration: none;
  }
  ul {
    margin: 0 auto;
    list-style-type: none;
  }
  img {
    margin: 0;
  }
  `
