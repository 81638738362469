import styled from 'styled-components'
import { Link } from 'gatsby'

export default styled(Link)`
  background: transparent;
  transition: 0.8s ease-in-out;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  margin: ${props => props.margin || '10px'};
  border-radius: 4px;
  text-align: center;
  font-family: ${props =>
    props.theme.fonts[props.font] || props.theme.fonts['Helvetica-light']};
  background: ${props =>
    props.background
      ? props.theme.colors[props.background] || props.background
      : props.theme.colors.yellowMelior};
  color: ${props =>
    props.color
      ? props.theme.colors[props.color] || props.color
      : props.theme.colors.white};
  border: none;
`
