import styled, { keyframes, css } from 'styled-components'
import { size } from '../theme/deviceSize'

const pricing = keyframes`
  0% {
    opacity: 1;
    transform: translateY(-10px);
  }

  25%{
    opacity: 0;
    transform: translateY(-20px);
  }

  50%{
    opacity: 0;
    transform: translateY(20px);
  }

  100 {
    opacity: 1;
      transform: translateY(0px);
  }
`

const animationRule = css`
  ${pricing} 700ms;
`

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  width: 32%;
  max-width: 360px;
  padding: 40px;
  border: 2px solid ${props => props.theme.colors.black};
  text-align: ${props => (props.lang === 'ar' ? 'right' : 'left')};
  border-radius: 8px;
  min-height: 580px;
  font-size: 0.7rem;
  margin: 10px 0;
  ul,
  h4,
  div,
  hr {
    width: 100%;
  }
  h2 {
    display: inline-block;
    font-size: 2.7rem;
    margin: 0;
  }

  h5 {
    margin: 0;
    margin-left: ${props => (props.lang === 'ar' ? '0' : '20px')};
    margin-right: ${props => (props.lang === 'ar' ? '20px' : '0px')};
    font-size: 1.2rem;
    color: ${props => props.theme.colors.gearGrey};
    position: relative;
  }
  h5.month::before {
    content: '/';
    position: absolute;
    left: ${props => (props.lang === 'ar' ? 'unset' : '-10px')};
    right: ${props => (props.lang === 'ar' ? '-10px' : 'unset')};
  }
  hr {
    height: 2px;
  }
  ul li {
    margin: 0;
    position: relative;
  }
  &:hover,
  &:focus {
    border-top: 4px solid ${props => props.theme.colors.yellowMelior};
    padding-top: 38px;
    transition: 0.2s;
  }
  &:hover .price {
    animation: ${animationRule};
  }
  &:hover .money {
    animation: ${animationRule};
    animation-delay: 0.05s;
  }
  &:hover ul li::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 9px;
    left: ${props => (props.lang === 'ar' ? 'unset' : '-15px')};
    right: ${props => (props.lang === 'ar' ? '-15px' : 'unset')};
    width: 8px;
    height: 8px;
    background: ${props => props.theme.colors.gearGrey};
    border-radius: 50%;
    transition: 0.2s;
  }
  a {
    color: ${props => props.theme.colors.yellowMelior};
  }
  ul {
    font-weight: bold;
  }
  li {
    font-weight: 300;
  }
  a.active {
    background: transparent;
    outline: none;
    cursor: pointer;
    padding: 10px 20px;
    margin: ${props => props.margin || '10px'};
    border-radius: 4px;
    font-family: ${props =>
      props.theme.fonts[props.font] || props.theme.fonts['Helvetica-light']};
    background: ${props =>
      props.background
        ? props.theme.colors[props.background] || props.background
        : props.theme.colors.yellowMelior};
    color: ${props =>
      props.color
        ? props.theme.colors[props.color] || props.color
        : props.theme.colors.white};
    border: none;
    text-align: center;
  }
  &:hover a.active {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.yellowMelior};
    transition: 0.2s;
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.yellowMelior};
  }
  &:hover button.active {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.yellowMelior};
    transition: 0.2s;
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.yellowMelior};
  }

  .optionsSection {
    min-height: 230px;
    margin: 15px;
  }

  img {
    width: 15px;
    height: 10px;
    cursor: pointer;
    margin: 10px;
  }

  input[type='checkbox'] {
    cursor: pointer;
    visibility: hidden;
  }
  input:checked {
    color: green;
  }

  .checboxMain {
    display: block;
    position: relative;
    padding-left: 15px;
    margin: 0px 5px;
    cursor: pointer;
  }
  .openDropdownButton {
    background: transparent;
    border: none;
    cursor: pointer;
    display: contents;
  }

  .label {
    font-size: 1.1rem;
    font-family: ${props => props.theme.fonts['Helvetica-MediumCond']};
  }

  /* Creating a custom checkbox 
        based on demand */
  .checkboxMark {
    position: absolute;
    top: 2.5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: ${props => props.theme.colors.white};
    border: 1px solid;
    border-color: ${props => props.theme.colors.lightGrey};
    border-radius: 2px;
  }

  &:hover .checkboxMark {
    border: 3px solid;
    border-color: ${props => props.theme.colors.lightGrey};
  }
  /* Specify the background color to be 
        shown when hovering over checkbox */
  .checboxMain:hover input ~ .checkboxMark {
    border-color: ${props => props.theme.colors.yellowMelior};
  }

  /* Specify the background color to be 
        shown when checkbox is checked */
  .checboxMain input:checked ~ .checkboxMark {
    background-color: ${props => props.theme.colors.yellowMelior};
    border-color: ${props => props.theme.colors.yellowMelior};
  }

  /* Checkmark to be shown in checkbox */
  /* It is not be shown when not checked */
  .checkboxMark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Display checkmark when checked */
  .checboxMain input:checked ~ .checkboxMark:after {
    display: block;
  }

  /* Styling the checkmark using webkit */
  /* Rotated the rectangle by 45 degree and  
    showing only two border to make it look 
    like a tickmark */
  .checboxMain .checkboxMark:after {
    left: 3.5px;
    bottom: 3.5px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @media (max-width: ${size.laptop}) {
    padding: 25px;
    h2 {
      font-size: 2rem;
    }
    h5 {
      font-size: 0.8rem;
    }
    ul li {
      font-size: 0.75rem;
    }
    a.active {
      font-size: 0.9rem;
      padding: 10px;
    }
    .optionsSection {
      min-height: 320px;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    margin: 10px 0;
    padding: 30px;
    a.active {
      font-size: 0.9rem;
      padding: 10px 20px;
    }
    .optionsSection {
      min-height: unset;
    }
  }
`
