import styled from 'styled-components'

export default styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px 0;
  flex-wrap: wrap;
  position: relative;

  label {
    width: 20%;
  }
  textarea {
    border-radius: 0;
    width: 60%;
    border: 0;
    border-bottom: 1px solid black;
    outline: none;
    resize: none;
    &.error {
      color: ${props => props.theme.colors.redFail};
      border-color: ${props => props.theme.colors.redFail};
    }
  }
  @media (max-width: 580px) {
    justify-content: center;
    Input {
      width: 90%;
    }
    Select {
      width: 90%;
    }
    label {
      width: 90%;
    }
    textarea {
      width: 100%;
    }
  }
`
