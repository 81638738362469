import HomePage1 from './homePage1'
import HomePageMain from './homePageMain'
import MeliorLogo from './meliorLogo'
import Service1 from './service1'
import Service2 from './service2'
import Service3 from './service3'
import MeliorDashboard from './meliorDashboard'
import ShamseyaLogo from './shamseyaLogo'
import MeliorIcon from './meliorIcon'
import TabletAppExample from './tabletAppExample'
import Contact from './contact'
import Mobile from './mobile'
import LiBlock from './block'
import LiPress from './liPress'
import LiPie from './liPie'
import LiStar from './liStar'
import Hand from './hand'
import Product from './product'
import Product_ar from './product_ar'
import Desktop from './desktop'
import Wire from './wire'
import Stats from './stats'
import Plant from './plant'
import GooglePlayBadge from './googlePlayBadge'
import MeliorLogoWhite from './meliorLogoWhite'

const getImage = imgName => {
  if (imgName === 'melior-logo.png') return MeliorLogo()
  if (imgName === 'melior-logo-all-white.png') return MeliorLogoWhite()
  if (imgName === 'Laptop and tablet first.png') return HomePage1()
  if (imgName === 'home-page-main.png') return HomePageMain()
  if (imgName === 'service1-monitor.png') return Service1()
  if (imgName === 'service2-track.png') return Service2()
  if (imgName === 'service3-improve.png') return Service3()
  if (imgName === 'shamseya-logo.png') return ShamseyaLogo()
  if (imgName === 'melior-icon.png') return MeliorIcon()
  if (imgName === 'melior-dashboard-extra.png') return MeliorDashboard()
  if (imgName === 'tablet-app-example-extra.png') return TabletAppExample()

  if (imgName === 'contact.png') return Contact()
  if (imgName === 'mobile.png') return Mobile()
  if (imgName === 'block.png') return LiBlock()
  if (imgName === 'liPress.png') return LiPress()
  if (imgName === 'liPie.png') return LiPie()
  if (imgName === 'liStar.png') return LiStar()
  if (imgName === 'hand.png') return Hand()
  if (imgName === 'desktop.png') return Desktop()
  if (imgName === 'product.png') return Product()
  if (imgName === 'product_ar.png') return Product_ar()
  if (imgName === 'wire.png') return Wire()
  if (imgName === 'stats.png') return Stats()
  if (imgName === 'plant.png') return Plant()
  if (imgName === 'google-play-badge.png') return GooglePlayBadge()
}

export { getImage }
