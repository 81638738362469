import styled from 'styled-components'

export default styled.button`
  background: transparent;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  transition: 0.5s ease-in-out;
  margin: ${props => props.margin || '10px'};
  border-radius: 4px;
  font-family: ${props =>
    props.theme.fonts[props.font] || props.theme.fonts['Helvetica-light']};
  background: ${props =>
    props.background
      ? props.theme.colors[props.background] || props.background
      : props.theme.colors.yellowMelior};
  font-size: ${props => props.fontSize || '1.1rem'};
  color: ${props =>
    props.color
      ? props.theme.colors[props.color] || props.color
      : props.theme.colors.white};
  border: none;
  &:disabled {
    background: #aaa;
    cursor: default;
  }
`
