/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'

import './layout.css'
import { GlobalStyle } from '../theme/globalStyle'
import { theme } from '../theme/theme'
import styled, { ThemeProvider } from 'styled-components'

const Layout = ({ className, children, lang, disableArabicLanguage }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header lang={lang} disableArabicLanguage={disableArabicLanguage} />
      <div className={className}>
        <main className="main">{children}</main>
      </div>
      <Footer lang={lang} />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default styled(Layout)`
  margin: 0 auto;
  max-width: 1100px;
  margin-top: 100px;
  padding: 0 1.0875rem 1.45rem;
  .main {
    min-height: 65vh;
  }
  @media (max-width: 768px) {
    margin-top: 55px;
  }
`
