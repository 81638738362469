import styled, { keyframes } from 'styled-components'

const ding = keyframes`
0% { transform: scale(1); }
12.5% { transform: scale(.95); }
17.5% { transform: scale(.85); }
22.5% { transform: scale(1.15); }
27.5% { transform: scale(.85); }
32.5% { transform: scale(1.15); }
37.5% { transform: scale(1.09); }
50% { transform: scale(1); }
100% { transform: scale(1); }
`

export default styled.div`
  width: ${props => (props.size ? props.size : '100px')};
  height: ${props => (props.size ? props.size : '100px')};
  background: ${props => props.theme.colors.circleOrange};
  border-radius: 50%;
  z-index: -1;

  position: absolute;
  top: ${props => (props.top ? props.top : '')};
  bottom: ${props => (props.bottom ? props.bottom : '')};
  left: ${props => (props.left ? props.left : '')};
  right: ${props => (props.right ? props.right : '')};

  animation: ${ding} ${props => (props.duration ? props.duration : '')};
  animation-delay: ${props => (props.delay ? props.delay : '')};
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @media (max-width: 790px) {
    top: ${props => (props.medium_top ? props.medium_top : props.small_top)};
    bottom: ${props =>
      props.medium_bottom ? props.medium_bottom : props.small_bottom};
    right: ${props =>
      props.medium_right ? props.medium_right : props.small_right};
    left: ${props =>
      props.medium_left ? props.medium_left : props.small_left};
  }
  @media (max-width: 580px) {
    width: ${props => (props.minsize ? props.minsize : props.size)};
    height: ${props => (props.minsize ? props.minsize : props.size)};

    top: ${props => (props.small_top ? props.small_top : props.top)};
    bottom: ${props =>
      props.small_bottom ? props.small_bottom : props.bottom};
    right: ${props => (props.small_right ? props.small_right : props.right)};
    left: ${props => (props.small_left ? props.small_left : props.left)};
  }
`
