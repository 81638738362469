import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getImage } from '../imageContainers'
import { FlexContainer, Button, ButtonLink } from '../elements'
import burgerIcon from '../images/burgerIcon.png'
import cancelIcon from '../images/cancel.svg'
import { BUTTONS } from '../CMS/Navbar'

import { slide as Menu } from 'react-burger-menu'

const HeaderComp = styled.header`
  background: transparent;
  border-bottom: 1px solid ${props => props.theme.colors.blackBright};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
`

const NavButton = styled(Button)`
  border-bottom: 2px solid transparent;
  padding: 1px 1px 5px;
  border-radius: 0;
  margin: 2px;
  font-size: 0.9rem;
  letter-spacing: ${props => (props.lang === 'en' ? '1px' : 0)};
  &:hover {
    border-bottom: 2px solid ${props => props.theme.colors.yellowMelior};
  }
  @media (max-width: 768px) {
    display: none;
  }
`
const SubscribeButton = styled(ButtonLink)`
  transition: 0.5s ease-in-out;
  font-size: 0.9rem;
  &:hover {
    background: ${props => props.theme.colors.yellowMeliorGentle};
    color: ${props => props.theme.colors.black};
    transform: scale(1.15);
  }
  @media (max-width: 426px) {
    display: none;
  }
`
const LoginButton = styled.a`
  border-bottom: 2px solid transparent;
  padding: 5px 20px;
  border-radius: 0;
  font-size: 0.9rem;

  color: ${props => props.theme.colors.black};
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid ${props => props.theme.colors.yellowMelior};
  }
  @media (max-width: 425px) {
    display: none;
  }
`
const LangGroup = styled.div`
  padding: 5px;
  border-radius: 0;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.yellowMelior};
  @media (max-width: 585px) {
    display: none;
  }
`
const LangButton = styled(Link)`
  padding: 10px;
  font-size: 0.65rem;
  color: ${props => props.theme.colors.black};
  cursor: pointer;
  border-bottom: transparent;

  &:hover {
    color: ${props => props.theme.colors.yellowMelior};
  }

  @media (max-width: 768px) {
    display: ${props => (props.menu === 'true' ? 'block' : 'none')};
  }
`

const Header = ({ className, lang = 'en', disableArabicLanguage }) => {
  const [, setSrollPosition] = useState(0)

  const changeLang = lang => {
    if (disableArabicLanguage) return
    if (typeof window !== 'undefined') {
      var path = window.location.pathname.split('/')
      if (
        !path[1] ||
        (!(path[1] === 'en' || path[1] === 'ar') && lang !== 'en')
      ) {
        return '/ar' + path.join('/')
      } else if (lang === 'ar') {
        return window.location.pathname.replace('en', 'ar')
      } else if (lang === 'en') {
        return window.location.pathname.replace('ar', 'en')
      }
    }
  }

  const handleScroll = () => {
    const position = window.pageYOffset
    let header = document.getElementById('myHeader')
    setSrollPosition(position)
    if (position > 50) {
      // header is not at top
      header.classList.add('moving')
    } else {
      // header at top
      header.classList.remove('moving')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <HeaderComp
      id="header"
      className={className}
      dir={lang === 'ar' ? 'rtl' : 'auto'}
    >
      <div className="upper" id="myHeader">
        <FlexContainer
          className="body"
          width="100%"
          maxWidth="1100px"
          justifyContent="space-between"
        >
          <FlexContainer alignItems="center" justifyContent="center">
            <div className="logo">
              <Link to={lang && `/${lang}`}>{getImage('melior-logo.png')}</Link>
              {/* <Link to={lang && `/${lang}`}>
                {getImage('melior-logo-all-white.png')}
              </Link> */}
            </div>
            <NavButton lang={lang} color="black" background="transparent">
              <Link to={lang && `/${lang}`} className="link">
                {BUTTONS[lang][0]}
              </Link>
            </NavButton>
            <NavButton lang={lang} color="black" background="transparent">
              <Link to={lang && `/${lang}/pricing`} className="link">
                {BUTTONS[lang][1]}
              </Link>
            </NavButton>
            <NavButton lang={lang} color="black" background="transparent">
              <Link to={lang && `/${lang}/product-tour`} className="link">
                {BUTTONS[lang][2]}
              </Link>
            </NavButton>
            {/* <NavButton lang="en" color="black" background="transparent">
              <Link to={`/blog`} className="link">
                {BUTTONS[lang][3]}
              </Link>
            </NavButton> */}
          </FlexContainer>
          <FlexContainer alignItems="center">
            <LoginButton
              target="blank"
              href="https://dashboard.mymelior.com/"
              font="Helvetica-Medium"
              color="yellowMelior"
              background="transparent"
            >
              {BUTTONS[lang][5]}
            </LoginButton>
            <SubscribeButton
              font="Helvetica-Medium"
              to={lang && `/${lang}/pricing`}
            >
              {BUTTONS[lang][6]}
            </SubscribeButton>
            {!!!disableArabicLanguage && (
              <LangGroup dir={lang === 'ar' ? 'rtl' : 'auto'}>
                <LangButton
                  to={changeLang('en')}
                  className={lang === 'en' ? 'changelang' : ''}
                >
                  {BUTTONS['en'][4]}
                </LangButton>
                |
                <LangButton
                  to={changeLang('ar')}
                  className={lang === 'en' ? '' : 'changelang'}
                >
                  {BUTTONS['ar'][4]}
                </LangButton>
              </LangGroup>
            )}
            <div className="mobile-menu">
              <Menu
                width={220}
                right={lang !== 'ar'}
                customBurgerIcon={<img src={burgerIcon} alt="nav icon" />}
                burgerButtonClassName={'burgerIcon'}
                crossButtonClassName={'crossIcon'}
                crossClassName={'crossIcon'}
                customCrossIcon={
                  <img src={cancelIcon} alt="cancel icon" dir="rtl" />
                }
                menuClassName={'menu'}
                disableAutoFocus
                dir="rtl"
              >
                <Link to={lang && `/${lang}`} className="link white">
                  {BUTTONS[lang][0]}
                </Link>
                <Link to={lang && `/${lang}/pricing`} className="link white">
                  {BUTTONS[lang][1]}
                </Link>
                <Link
                  to={lang && `/${lang}/product-tour`}
                  className="link white"
                >
                  {BUTTONS[lang][2]}
                </Link>
                {/* <Link to={`/blog`} className="link white">
                  {BUTTONS[lang][3]}
                </Link> */}
                <Link to={lang && `/${lang}/contact-us`} className="link white">
                  {BUTTONS[lang][5]}
                </Link>
                <a
                  className="link white"
                  target="blank"
                  href="https://dashboard.mymelior.com/"
                >
                  {BUTTONS[lang][6]}
                </a>
                <LangGroup
                  dir={lang === 'ar' ? 'rtl' : 'auto'}
                  style={{ padding: '5px 10px' }}
                  className="white"
                >
                  <LangButton
                    to={changeLang('ar')}
                    className={lang === 'en' ? 'white' : 'changelang white'}
                    menu="true"
                  >
                    {BUTTONS['ar'][4]}
                  </LangButton>
                </LangGroup>
                <LangGroup
                  dir={lang === 'ar' ? 'rtl' : 'auto'}
                  style={{ padding: '5px 10px' }}
                  className="white"
                >
                  <LangButton
                    to={changeLang('en')}
                    className={lang === 'en' ? 'changelang white' : 'white'}
                    menu="true"
                  >
                    {BUTTONS['en'][4]}
                  </LangButton>
                </LangGroup>
                {!!!disableArabicLanguage && (
                  <>
                    <LangGroup
                      dir={lang === 'ar' ? 'rtl' : 'auto'}
                      style={{ padding: '5px 10px' }}
                      className="white"
                    >
                      <LangButton
                        to={changeLang('ar')}
                        className={lang === 'en' ? 'white' : 'changelang white'}
                        menu="true"
                      >
                        {BUTTONS['ar'][4]}
                      </LangButton>
                    </LangGroup>
                    <LangGroup
                      dir={lang === 'ar' ? 'rtl' : 'auto'}
                      style={{ padding: '5px 10px' }}
                      className="white"
                    >
                      <LangButton
                        to={changeLang('en')}
                        className={lang === 'en' ? 'changelang white' : 'white'}
                        menu="true"
                      >
                        {BUTTONS['en'][4]}
                      </LangButton>
                    </LangGroup>
                  </>
                )}
              </Menu>
            </div>
          </FlexContainer>
        </FlexContainer>
      </div>
    </HeaderComp>
  )
}

export default styled(Header)`
  .upper {
    transition: 0.5s ease-in-out;
    margin: 0 auto;
    max-width: 960;
    padding: 1rem;
  }
  .body {
    margin: auto;
  }
  .white {
    color: white !important;
    border: none !important;
  }
  .logo {
    transition: 0.5s ease-in-out;
    width: 10rem;
    min-width: 120px;
    margin: 0px 10px 15px 0px;
    @media (max-width: 425px) {
      width: 10rem;
      margin-bottom: 10px;
    }
  }

  .link {
    color: inherit;
    padding: 10px 20px;
  }
  .mobile-menu {
    display: none;
    div {
      left: ${props => (props.lang === 'ar' ? 0 : 'unset')};
    }
    @media (max-width: 768px) {
      display: block;
      padding: 0.5rem;
    }
  }
  .menu {
    margin-top: 75px;
    background: ${props => props.theme.colors.yellowMelior};
    height: 275px !important;
    padding: 10px;
    @media (max-width: 425px) {
      margin-top: 58px;
    }
    @media (min-width: 425px) {
      height: 275px !important;
    }
  }
  .bm-overlay {
    background: rgba(
      255,
      255,
      255,
      0.3
    ) !important; /* Will give you a white overlay */
  }
  .burgerIcon {
    @media (max-width: 768px) {
      display: block;
      width: 2rem;
      position: relative;
      height: 40px;
      cursor: pointer;
      margin: 0px 10px;
    }
  }
  .crossIcon {
    margin-top: 10vh;
    @media (max-width: 425px) {
      margin-top: 8vh;
    }
  }
  .bm-cross-button {
    width: 20px !important;
  }
  .moving {
    background: ${props => props.theme.colors.white};
    padding: 0.25rem;
    .logo {
      width: 10rem;
    }
    .menu {
      margin-top: 65px;
      @media (max-width: 425px) {
        margin-top: 50px;
      }
    }
  }
`
