export const theme = {
  colors: {
    black: '#000',
    blackBright: '#00000011',
    white: '#fff',
    yellowMelior: '#ffcf3e',
    yellowMeliorGentle: '#ffcf3e44',
    greenSuccess: '#1db401',
    greenSuccessGentle: '#1db40144',
    redFail: '#ba3616',
    redFailGentle: '#ba361644',
    circleOrange: '#ffeab8', //rgb( 255, 234, 184)
    gearGrey: 'rgb( 228, 228, 228)',
    grey: '#f2f2f2',
    lightGrey: '#e4e4e4',
  },
  fonts: {
    BigJohm: 'BigJohm',
    MyriadProRegular: 'MyriadProRegular',
    'Helvetica-light': 'Helvetica-light',
    'Helvetica-Medium': 'Helvetica-Medium',
    'Helvetica-MediumCond': 'Helvetica-MediumCond',
    'Myriadpro-bold': 'Myriadpro-bold',
  },
}
