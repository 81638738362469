import styled, { keyframes } from 'styled-components'

const floating = keyframes`
    0%   { opacity: 1; transform: translateY(0)     translateX(0);}
    10%  { transform: translateY(-40px) translateX(-60px); transition: 5s;}
    20%  { transform: translateY(-80px) translateX(-90px); opacity: 0.1; transition: 5s;}
    23%  { opacity: 1; transition: 5s;}
    30%  { transform: translateY(-100px) translateX(-60px); transition: 5s;}
    40%  { transform: translateY(-50px) translateX(-30px);  transition: 5s;}
    50%  { transform: translateY(50px)  translateX(0px); transition: 5s;}
    60%  { transform: translateY(20px)  translateX(40px); transition: 5s;}
    70%  { transform: translateY(50px)  translateX(70px); opacity: 0.1 transition: 5s;}
    73%  { opacity: 1; transition: 5s;}
    80%  { transform: translateY(10px) translateX(40px); transition: 5s;}
    90%  { transform: translateY(-50px) translateX(-30px); }
    100% { opacity: 1; transform: translateY(0)  translateX(0px); transition: 5s;}
`
export default styled.div`
  width: ${props => (props.size ? `${props.size}px` : '')};
  height: ${props => (props.size ? `${props.size}px` : '')};
  background: ${props => props.theme.colors.circleOrange};
  border-radius: 50%;
  z-index: -5;
  position: absolute;
  top: ${props => (props.top ? props.top : '')};
  bottom: ${props => (props.bottom ? props.bottom : '')};
  left: ${props => (props.left ? props.left : '')};
  right: ${props => (props.right ? props.right : '')};

  animation-duration: ${props => (props.duration ? props.duration : '')};
  animation-name: ${floating};
  animation-iteration-count: infinite;
  animation-fill-mode: ${props => (props.fill ? props.fill : '')};
  animation-delay: ${props => (props.delay ? props.delay : '')};
  animation-direction: ${props => (props.reverse ? 'reverse' : '')};

  &:before {
    content: '';
    width: ${props =>
      props.thickness
        ? `${props.size - props.thickness}px`
        : `${props.size - 8}px`};
    height: ${props =>
      props.thickness
        ? `${props.size - props.thickness}px`
        : `${props.size - 8}px`};
    background: ${props => props.theme.colors.white};
    position: absolute;
    top: ${props => (props.innerposition ? props.innerposition : '4px')};
    left: ${props => (props.innerposition ? props.innerposition : '4px')};
    border-radius: 50%;
    z-index: -5;
  }

  @media (max-width: 790px) {
    top: ${props => (props.medium_top ? props.medium_top : props.small_top)};
    bottom: ${props =>
      props.medium_bottom ? props.medium_bottom : props.small_bottom};
    right: ${props =>
      props.medium_right ? props.medium_right : props.small_right};
    left: ${props =>
      props.medium_left ? props.medium_left : props.small_left};
  }

  @media (max-width: 580px) {
    top: ${props => (props.small_top ? props.small_top : props.top)};
    bottom: ${props =>
      props.small_bottom ? props.small_bottom : props.bottom};
    right: ${props => (props.small_right ? props.small_right : props.right)};
    left: ${props => (props.small_left ? props.small_left : props.left)};
  }
`
