export const size = {
  mobileS: "325px",
  mobileM: "380px",
  mobileL: "430px",
  tablet: "773px",
  laptop: "1029px",
  laptopL: "1440px",
  desktop: "2565px",
}

export const deviceSize = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}
