import styled from 'styled-components'

export default styled.input`
  background: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid ${props => props.theme.colors.black};
  width: ${props => (props.width ? props.width : '80%')};
  outline: none;
  padding: 5px;
  position: relative;
  &.error {
    color: ${props => props.theme.colors.redFail};
    border-color: ${props => props.theme.colors.redFail};
  }
`
