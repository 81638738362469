import styled from 'styled-components'

export default styled.select`
  background: transparent;
  padding: 5px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid black;
  width: ${props => (props.width ? props.width : '80%')};
  outline: none;
  position: relative;
  option {
    font-size: 0.9rem;
  }
  &.placeholder {
    color: grey;
  }
  &.placeholder .option {
    color: ${props => props.theme.colors.black};
  }
  &.error {
    color: ${props => props.theme.colors.redFail};
    border-color: ${props => props.theme.colors.redFail};
    .option {
      color: #000;
    }
  }
`
