export const BUTTONS = {
  en: [
    'Home',
    'Pricing',
    'Product Tour',
    'Blog',
    'EN',
    'Login',
    'Subscribe Now',
  ],
  ar: [
    'الرئيسية',
    'الأسعار',
    'جولة عن المنتج',
    'مقالات',
    'AR',
    'تسجيل الدخول',
    'إنشاء حساب',
  ],
}
