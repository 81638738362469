import styled from 'styled-components'
import {size} from "../theme/deviceSize"

export default styled.div`
    width: 100%;
    padding: 0;
    margin: 0 auto;
    background: none;
    border:0;
    position: relative;
    @media(max-width: ${size.mobileM} ) {
        width: 90%;
    }
    @media(max-width: 580px ) {
        width: 82%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media(max-width: 1030px ) {
        width: 92%;
        
    }

`
