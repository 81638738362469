export const FOOTER = {
  en: {
    text: [
      'Contact us',
      'Terms & Conditions',
      'Privacy Policy',
      '2021 developed and managed by',
      'Shamseya for innovative community and healthcare solutions',
    ],
    link: 'https://shamseya.org/',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.shamseya.melior',
    googleImg: 'google-play-badge.png',
  },
  ar: {
    text: [
      'اتصل بنا',
      'شروط الاستخدام',
      'سياسة الخصوصية',
      '2021',
      'مؤسسة شمسیة لإدارة الأنظمة الصحیة',
    ],
    link: 'https://shamseya.org/',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.shamseya.melior',
    googleImg: 'google-play-badge.png',
  },
}
